import SessionProvider from "./SessionProvider";
import SearchProvider from "./SearchProvider";
import AddListModalProvider from "../components/AddListModal";
import ToastProvider from "./ToastProvider";
import EditSearchModalProvider from "../components/EditSearchModal";
import EditScreenerModalProvider from "../components/EditScreenerModal";
import EditSearchIterationModalProvider from "../components/EditSearchIterationModal";
import EditListModalProvider from "../components/EditListModal";
import KeywordSuggestionModalProvider from "../components/KeywordSuggestionModal";
import PageHeaderProvider from "./PageHeaderProvider";
import AddIntentModalProvider from "../components/AddIntentModal";
import RemoveIntentModalProvider from "../components/RemoveIntentModal";
import ReportIssueProvider from "./ReportIssueProvider";
import UserSettingsProvider from "./UserSettings";
import MediaQueryProvider from "./MediaQueryProvider";
import AddScreenerModalProvider from "../components/AddScreenerModal";
import DeleteScreenerModalProvider from "../components/DeleteScreenerModal";
import DuplicateScreenerModalProvider from "../components/DuplicateScreenerModal";
import AddToScreenerModalProvider from "../components/AddToScreenerModal";
import RemoveFromScreenerModalProvider from "../components/RemoveFromScreenerModal";

const AllProvider = ({ children }: { children: any }) => {
  return (
    <MediaQueryProvider>
      <ToastProvider>
        <ReportIssueProvider>
          <UserSettingsProvider>
            <PageHeaderProvider>
              <DuplicateScreenerModalProvider>
                <DeleteScreenerModalProvider>
                  <AddToScreenerModalProvider>
                    <RemoveFromScreenerModalProvider>
                      <AddScreenerModalProvider>
                        <AddIntentModalProvider>
                          <AddListModalProvider>
                            <RemoveIntentModalProvider>
                              <SearchProvider>
                                <EditScreenerModalProvider>
                                  <EditSearchModalProvider>
                                    <EditSearchIterationModalProvider>
                                      <EditListModalProvider>
                                        <KeywordSuggestionModalProvider>
                                          {children}
                                        </KeywordSuggestionModalProvider>
                                      </EditListModalProvider>
                                    </EditSearchIterationModalProvider>
                                  </EditSearchModalProvider>
                                </EditScreenerModalProvider>
                              </SearchProvider>
                            </RemoveIntentModalProvider>
                          </AddListModalProvider>
                        </AddIntentModalProvider>
                      </AddScreenerModalProvider>
                    </RemoveFromScreenerModalProvider>
                  </AddToScreenerModalProvider>
                </DeleteScreenerModalProvider>
              </DuplicateScreenerModalProvider>
            </PageHeaderProvider>
          </UserSettingsProvider>
        </ReportIssueProvider>
      </ToastProvider>
    </MediaQueryProvider>
  );
};

export default AllProvider;
