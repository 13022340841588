import ModalComponents from "../../components/Modal";
import { Button, TextField } from "@mui/material";
import * as React from "react";
import { useImmer } from "use-immer";
import { createContext, ReactNode, useContext, useEffect } from "react";
import axios from "axios";
import { ToastContext } from "../../providers/ToastProvider";
import { Watchlist } from "../../types/watchlist";

type EditListModalContextValue = {
  openUpdateList: (
    search: Watchlist,
    callbackFn?: (watchlist: Watchlist, newName: string) => void
  ) => void;
};

export const EditListModalContext = createContext<EditListModalContextValue>({
  openUpdateList: (
    list: Watchlist,
    callbackFn?: (watchlist: Watchlist, newName: string) => void
  ) => {},
});

const EditListModal = ({
  open,
  onClose,
  list,
  callbackFn,
}: {
  open: boolean;
  onClose: () => void;
  list?: Watchlist;
  callbackFn: (watchlist: Watchlist, newName: string) => void;
}) => {
  const { setSuccess, setError } = useContext(ToastContext);

  useEffect(() => {
    if (list) {
      setState((state) => {
        state.updatedListName = list.name;
      });
    }
  }, [list?.name]);

  const defaultState = {
    updatedListName: "",
    requestPending: false,
  };

  const [state, setState] = useImmer<{
    updatedListName: string;
    requestPending: boolean;
  }>(defaultState);

  const onSubmit = (
    callbackFn?: (watchlist: Watchlist, name: string) => void
  ) => {
    setState((state) => {
      state.requestPending = true;
    });
    axios({
      method: "PUT",
      url: `/api/v1/watchlists/${list?.id}`,
      withCredentials: true,
      data: {
        name: state.updatedListName,
      },
    })
      .then((response) => {
        if (callbackFn && list) {
          console.log("invoked callback");
          callbackFn(list, state.updatedListName);
        }
        setSuccess("List updated successfully");
      })
      .catch((error) => {
        setError("Error updating list");
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <ModalComponents.ModalContainer
      open={open}
      onClose={() => {
        onClose();
        setState(defaultState);
      }}
    >
      <ModalComponents.ModalPage
        title={`Update "${list?.name}"`}
        body={
          <TextField
            autoFocus
            value={state.updatedListName}
            label="Name"
            sx={{
              marginTop: "1rem",
            }}
            onChange={(e) => {
              setState((state) => {
                state.updatedListName = e.target.value;
              });
            }}
            fullWidth
          />
        }
        buttons={
          <>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={
                state.updatedListName === list?.name ||
                state.updatedListName.length === 0
              }
              onClick={() => {
                onSubmit(callbackFn);
                onClose();
              }}
            >
              Update
            </Button>
          </>
        }
      />
    </ModalComponents.ModalContainer>
  );
};

const EditListModalProvider = ({ children }: { children: ReactNode }) => {
  const defaultState = {
    open: false,
    callbackFn: () => {},
    requestPending: false,
    search: undefined,
    updatedSearchName: "",
  };

  const [state, setState] = useImmer<{
    open: boolean;
    callbackFn: (watchlist: Watchlist, newName: string) => void;
    requestPending: boolean;
    list?: Watchlist;
    updatedSearchName: string;
  }>(defaultState);

  const onClose = () => {
    setState(defaultState);
  };

  const noneCallbackFn = (watchlist: Watchlist, newName: string) => {};

  const context = {
    openUpdateList: (
      List: Watchlist,
      callbackFn?: (watchlist: Watchlist, newName: string) => void
    ) => {
      setState((state) => {
        state.list = List;
        state.open = true;
        state.callbackFn = callbackFn || noneCallbackFn;
      });
    },
  };

  return (
    <EditListModalContext.Provider value={context}>
      {children}
      <EditListModal
        open={state.open}
        list={state.list}
        onClose={onClose}
        callbackFn={state.callbackFn}
      />
    </EditListModalContext.Provider>
  );
};

export default EditListModalProvider;
