import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SessionContext } from "../providers/SessionProvider";

export const Protected = ({ children }: { children: any }) => {
  const session = useContext(SessionContext);

  if (!session.exists) {
    // Redirect if session doesn't exist
    return <Navigate to="/login" replace />;
  }

  const isPaywall =
    session.user?.organization.billingInformation.status === "Paywall";
  const needsRedirect = window.location.pathname === "/accounts";

  // Redirect if paywall applies
  return isPaywall && !needsRedirect ? (
    <Navigate to="/accounts" replace />
  ) : (
    children
  );
};

export const Admin = ({ children }: { children: any }) => {
  const session = useContext(SessionContext);

  return (
    <Protected>
      {/* TODO: show toast and bounce to previous page */}
      {session.user?.isAdmin ? children : <Navigate to={"/searches"} replace />}
    </Protected>
  );
};
